'use strict';
var linkCss = document.createElement("link");
linkCss.rel = "stylesheet";
linkCss.href = "https://fonts.googleapis.com/css?family=Open+Sans:300,400,700|Josefin+Sans:400,700";
var siteHeadElem = document.head || document.getElementsByTagName("head")[0];
siteHeadElem.appendChild(linkCss);
var barbaJs = {
	init: function(){
		Barba.Pjax.start();
		Barba.Dispatcher.on('transitionCompleted', function() {
			
			$('html, body').scrollTop(0);
			mm_track_pageview(location.pathname);
			siteGlobal.rebuildAllEvents();

		});

		Barba.Dispatcher.on('newPageReady',function(currentStatus, oldStatus, container){
			var js = container.querySelectorAll("script");
			if(js != null && js.length>0){
				for(i in js){
					if(js[i].innerHTML!=='') eval(js[i].innerHTML);
					if(js[i].src!=='') $.getScript(js[i].src);
				}
			}
			$('body').removeClass('menu_open');
		});

		Barba.Dispatcher.on('linkClicked', function() {
		});

		Barba.Pjax.getTransition = function() {
			return FadeTransition;
		};

		Barba.Pjax.originalPreventCheck = Barba.Pjax.preventCheck;
		
		Barba.Pjax.preventCheck = function(evt, element) {
			if (!Barba.Pjax.originalPreventCheck(evt, element)) {
				return false;
				alert('');
			}
			// No need to check for element.href -
			// originalPreventCheck does this for us! (and more!)
			if (/.pdf/.test(element.href.toLowerCase())) {
				return false;
			}
			return true;
		};
	}
}

FadeTransition = Barba.BaseTransition.extend({
	start: function() {
		Promise.all([this.newContainerLoading, this.fadeOut()]).then(this.fadeIn.bind(this));
	},
	fadeOut: function() {
		return $(this.oldContainer).animate({ opacity: 0 }).promise();
	},

	fadeIn: function() {
		var _this = this;
		var $el = $(this.newContainer);

		$(this.oldContainer).hide();

		$el.css({
			visibility : 'visible',
			opacity : 0
		});

		$el.animate({ opacity: 1 }, 400, function() {
			_this.done();
		});
	}
});
var visual = {
	init: function(){
    $('.homeVisual__img').fadeOut('fast');
    $('.homeVisual__slider').slick({
      arrows:true,
      autoplay: true,
      autoplaySpeed: 4000,
      fade: true,
      pauseOnHover: false,
      arrow: true
    });
    $('.homeGallery__img').fadeOut('fast');
    $('.homeGallery__slider').slick({
      arrows:true,
      autoplay: true,
      autoplaySpeed: 4000,
      fade: true,
      pauseOnHover: false,
      arrow: true
    });
    $('.genericSlider__slider').slick({
      arrows: true,
      prevArrow: '<button type="button" class="slick-prev"><img src="/images/svg/slick-arrow.svg"></button>',
      nextArrow: '<button type="button" class="slick-next"><img src="/images/svg/slick-arrow.svg"></button>'
    });
  }
}
var topBar = {
	init: function(){
    $('.topBar__services__lang').on('click',function() {
      $(this).toggleClass('lang__open');
    });
    $('.topBar__burger').on('click',function() {
      if ($('html,body').scrollTop() > 50) {
        $('body').toggleClass('menu_open');
        $('body').toggleClass('sticked');
      } else {
        $('body').toggleClass('menu_open');
        if ($('body').find('.t_page_home').length == 0) {
          $('body').toggleClass('sticked');
        }
      }
    });
    if ($('body').find('.t_page_home').length == 0 && !$('body').hasClass('menu_open')) {
      $('body').addClass('sticked');
    }
  },
  onScroll: function () {
    if ($('html,body').scrollTop() > 50 && $('body').find('.t_page_home').length > 0) {
      $('body').addClass('sticked');
      document.getElementById('SVG__logo').setAttribute("viewBox", "0 0 274.3 60");
    } else {
      if ($('body').find('.t_page_home').length > 0) {
        $('body').removeClass('sticked');
      }
      document.getElementById('SVG__logo').setAttribute("viewBox", "0 0 274.3 130.8");
    }
  }
}
var menuOverlay = {
	init: function(){
    $('.menuOverlay > ul li.has_sub a').on('click',function() {
      if($(this).hasClass('active')) {
        $(this).removeClass('active');
        $(this).closest('li').removeClass('active');
      } else {
        $(this).addClass('active');
        $(this).closest('li').addClass('active');
      }
    });
  }
}
'use strict';

var multimedia = {

  init: function () {
    this.filtersInit();
    this.blueImpInit();
  },

  filtersInit: function () {

    var $container = $('.pageGal__list').isotope({
      // options
      itemSelector: '.pageGal__item',
      layoutMode: 'fitRows',
    });

    $('.filters__link').click(function () {
      var filterValue = $(this).attr('data-filter');
      $(this).parent().siblings().children('a').removeClass('filters__link--selected');
      $(this).addClass('filters__link--selected');
      multimedia.filterProducts($container, filterValue);
    });

  },

  filterProducts: function (container, filterValue) {

    container.isotope({
      filter: function () {

        if (filterValue != '*') {
          var itemValues = String($(this).attr('data-typ-val')).split(',');
          var itemValueFound = $.inArray(filterValue, itemValues);
          itemPassFilter = (itemValueFound == -1) ? false : true;
        } else {
          itemPassFilter = true;
        }

        return itemPassFilter;
      }
    });

  },

  blueImpInit: function () {
    if ($('.myBlueImp').length > 0) {
      $('body').append('<div id="blueimp-gallery" class="blueimp-gallery blueimp-gallery-controls" data-you-tube-click-to-play="false"><div class="slides"></div><h3 class="title"></h3><a class="prev">‹</a><a class="next">›</a><a class="close">×</a></div>');
      $('.myBlueImp').click(function (event) {
        event = event || window.event;
        var target = event.target || event.srcElement,
          link = target.src ? target.parentNode : target,
          options = {
            index: link,
            event: event,
            onclosed: function () {
              setTimeout(function () {
                $('body').css({ overflow: 'auto' });
              }, 150);
            }
          },
          links = this.getElementsByTagName('a');
        blueimp.Gallery(links, options);
      });
    }
  },
}
/*
 * Custom code goes here.
 * A template should always ship with an empty custom.js
 */
var mm_dataone_config = {
  "owner_id": 52,
  "forms": [
    {
      "form_type": 'contact',
      "form_id": '.myreply_form',
      "term_id": '379483bf-31d4-413a-ba1e-8cc82faeb8b0',
      "classes_add": [
        ['[name="name"]', 'mm-prv-name'],
        ['[name="lastname"]', 'mm-prv-name'],
        ['[name="email"]', 'mm-prv-email'],
        ['[name="privacy"]','dati_personali'],
        ['[name="newsletter_optin"]','newsletter']
      ],
      "submit": '.bt_send_form',
      "el_to_remove": [],
      "el_to_wrap": '.privacy_newsletter_wrapper',
      "el_to_before": '',
      "el_to_hide": []
    }
  ]
}

var mm_dataone = {
  /* multi form case with bootsrap nav */
  init: function (activetab) {

    function microtime() {
      /* cache busting */
      var d = new Date();
      var n = d.getTime();
      return n;
    }
    
    //$('head').append('<link data-cssone="1" rel="stylesheet" type="text/css" href="https://static.dataone.online/widget/mmprvwidget.css?d=' + microtime() + '">');
    
    var forms = mm_dataone_config.forms;

    /* reduce object with tab form active */
    /* decomment for multiple form in tabs */
    // var tmp = [];
    // $.each(forms, function (index,val) {
    //   if (val.form_id == '#'+$(activetab).find(val.form_id).attr('id')) {
    //     tmp.push(val);
    //     return false;
    //   }
    // });

    // forms = tmp;
  
    /* loop array forms */
    $.each(forms, function (index, form) {
      if ($(form.form_id).length > 0) {
        $('form').removeClass('mm-prv-form');
        /* main class assign */
        if ($(form.form_id).is("form")) { /* check if element is form */
          $(form.form_id).addClass('mm-prv-form');
        } else {
          $(form.form_id).find('form').addClass('mm-prv-form');
        }

        /* get submit text and classes */
        var submit_text = $(form.form_id).find(form.submit).text();
        var submit_classes = $(form.form_id).find(form.submit).attr('class');

        /* add dataone classes to fields */
        $.each(form.classes_add, function (idx, field) {
          $(form.form_id).find(field[0]).addClass(field[1]);
        });

        /* find submit button and add dataone classes */
        $(form.form_id).find(form.submit).addClass('mm-prv-form-submit');
        
        /* elements to remove */
        $.each(form.el_to_remove, function (idx, remove) {
          $(form.form_id).find(remove).remove();
        });
        
        /* append dataone wrapper */
        /* case to wrap */
        if (form.el_to_wrap != '' && $(form.form_id).find(form.el_to_wrap).length > 0) {
          $(form.form_id).find(form.el_to_wrap).wrapAll('<div id="mm-prv-wdg"></div>');
        }
        /* case append */
        if (form.el_to_before != '' && $(form.form_id).find(form.el_to_before).length > 0) {
          $('<div id="mm-prv-wdg"></div>').insertBefore($(form.form_id).find(form.el_to_before));
        }
        /* case custom */
        $(form.form_id).find('[name="privacy"]').closest('.col-12').find('.w-100').wrapAll('<div id="mm-prv-wdg"></div>');

        /* add hiddens */
        $.each(form.el_to_hide, function (idx, hidden) {
          $('<input type="hidden" name="' + hidden[0] + '" value="' + hidden[1] + '">').insertBefore($('#mm-prv-wdg'));
        });

        var term_id = form.term_id;
        /* setting */
        window.prwidget = {
          language: getLangCode,
          submitCaption: submit_text,
          submitClass: '',
          submitAttributes: [],
          ownerId: mm_dataone_config.owner_id,
          termId: term_id
        }

        /* append js widget */
        $.getScript('https://static.dataone.online/widget/mmprvwidget.js?d=' + microtime());
        return false;
      }
    });
  }
}
'use strict';
var siteGlobal = {

  options : {
    breakpoints: { 480: 'xxs',768: 'xs', 980: 'sm', 1200: 'md', 5000: 'lg' },
  },

  defaultScrollOffset:0,
  readmoreCollapsedHeight: 620,

  initReady: function () {
    this.breakPointUtility();
    this.goToPosOffsetCalc();
    this.linkAll();
    this.linkParts();
    topBar.init();
    this.readMoreInit();
    this.roomSlider();
    setTimeout(function () {
      mm_dataone.init();
    }, 1000);

    $('#accordionRooms').on('show.bs.collapse', function () {
      setTimeout(function () {
        $('.accordionRooms__room__slider').slick('unslick');
        siteGlobal.roomSlider();
      }, 150);
    });
  },

  initLoad: function () {
    this.goToPosOffsetCalc();
    this.breakPointUtility();
    setTimeout(function(){
      lazyLoad.init();
    },150);
    this.gmapPageInit();
    topBar.onScroll();
    $('[data-toggle="tooltip"]').tooltip();  
    visual.init();
    //enquiry.init();
    multimedia.init();
  },
  
  initFirstLoad: function(){
    barbaJs.init();
    this.overlayAllOff();
    menuOverlay.init();
  },

  rebuildAllEvents: function(){
    this.initReady();
    this.initLoad();
  },

  initResize: function(){
    this.breakPointUtility();
  },

  initResizeEnd: function(){
    this.goToPosOffsetCalc();
  },

  initScroll: function(){
    topBar.onScroll();
  },


  /**
   * OPEN MODAL (Usata di solito per social)
   * @param  {string} url : url pagina da aprire nella nuova finestra
   */
  openModal:function(url) {
    url = decodeURIComponent(((url) + '').replace(/\+/g, '%20'));
    window.open(url, 'socials', 'height=450,width=880,toolbar=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,modal=yes');
  },


  /**
   * ELEMENTI INTERAMENTE LINKABILI
   */
  linkAll: function(){
    $('.linkFrom').each(function() { 
        var elemToLink = $(this).parents('.linkTo');
        var pageLink = $(this).attr('href');
        var linkTarget = (typeof $(this).attr('target')!=='undefined') ?  $(this).attr('target') : '' ;
        var nobarba = $(this).hasClass('no-barba');
      if(pageLink !== undefined && pageLink!=='javascript:;') {
        elemToLink.addClass('pointer').click({myLink:pageLink, myTarget:linkTarget, nobarba:nobarba},function(e){
          e.preventDefault();
          if(e.data.myTarget==='_blank'){
            window.open(e.data.myLink);
          } else {
            if(typeof barbaJs !=='undefined' && !e.data.nobarba){
              Barba.Pjax.goTo(e.data.myLink); 
            } else {
              location.href = e.data.myLink;
            }
          }
        });
      }
    }); 
  },

  /**
   * ELEMENTI PARZIALMENTE LINKABILI (aree diversificate all'interno dello stesso contenitore)
   */
  linkParts: function(){
    var elems = $('.linkFromPart');
    var elemsLg = elems.length;
    if(elemsLg>0){
      var i=0;
      for (i; i<=elemsLg-1; i++) {
        var el = $(elems[i]);
        var pageLink = el.attr('href');
        if(pageLink !== undefined) {
          var blockWrap = el.parents('.linkWrap');
          var elemsToLink = $('.linkToPart',blockWrap);
          var nobarba = $(this).hasClass('no-barba');
          elemsToLink.addClass('pointer').click({pageLink:pageLink,nobarba:nobarba},function(e){
            if(typeof barbaJs !=='undefined' && !e.data.nobarba){
              Barba.Pjax.goTo(e.data.pageLink); 
            } else {
              location.href = e.data.pageLink;
            }
          });
        }
      }
    }
  },

  /**
   * COMPORTAMENTI JS A SECONDA DELLA DIMENSIONE PAGINA
   */
  breakPointUtility: function(){

    siteGlobal.breakPoint = 'xs';

    for (var el in this.options.breakpoints){
      if (this.options.breakpoints.hasOwnProperty(el)) {
        if ( $(window).width() <= (el-1) ){
            siteGlobal.breakPoint = this.options.breakpoints[el];
            break;
        }
      }
    }      
  },

  /**
   * OVERLAY LOADER
   */
  overlayAllOff : function() {
      setTimeout(
          function(){
              $('.overlay-all').remove();;
          },
      150);   
  },

  /**
   * CALCOLA OFFSET PER ANIMAZIONE SCROLL PAGINA
   */
  goToPosOffsetCalc:function(status){
    siteGlobal.defaultScrollOffset = 0;
    
    // Elementi da considerare per l'offset (un selettore per elemento)
    // Es. var offsetElements=['#barTopWrap'];
    var offsetElements=[];
    
    if (offsetElements.length > 0) {
      for (var el in offsetElements){
        var $el = $(offsetElements[el]);
        if($el.length>0){

          // L'elemento viene considerato nel calcolo solo se è in position fixed
          var elPos = $el.css('position');
          if(elPos === 'fixed') siteGlobal.defaultScrollOffset+= $el.outerHeight(true);
        }
      }
    }

    // Gestisco il padding del body rispetto all'offset
    $('body').css({'paddingTop':siteGlobal.defaultScrollOffset});


  }, 

  /**
   * SCROLL PAGINA
   */
  goToPos: function(ref,offset){
    // Offset Default con calcolo barre menu top
    var defaultOffset = siteGlobal.defaultScrollOffset;

    if(typeof offset === 'undefined') offset = defaultOffset;
    $ref = (typeof ref==='string') ? $(ref) : ref ;
    if($(ref).length==0)
      return false;
    var myPos = $ref.offset().top-offset;
    $('html,body').animate({
      scrollTop: myPos}, 
      { duration: 600 }
    );
  },

  gmapPageInit:function(){
    if($('#gmap_jquery').length>0 && typeof mapConfig!=='undefined') {
     mapConfig.params.drag = ($(document).width() > 768) ? true : false;
     setDirection.set(
      'gmap_jquery', 
      mapConfig.config,
      mapConfig.params.zoom,
      mapConfig.params.type,
      mapConfig.params.showCursor,
      mapConfig.params.drag,
      mapConfig.params.scroll
     );
    }
  },

  readMoreInit:function() {
    if ($(window).width() >= 768 && $(window).width() < 992) {
     this.readmoreCollapsedHeight = 700
    }
    if ($(window).width() < 768) {
      this.readmoreCollapsedHeight = 1050
    }
    $('.readmoreText').readmore({ 
      collapsedHeight: this.readmoreCollapsedHeight,
      moreLink: '<a href="#" class="discover_more text-center"> <i class="material-icons"> expand_more</i></a>',
      lessLink: '<a href="#" class="discover_more text-center"><i class="material-icons">keyboard_arrow_up</i ></a>',
      speed: 200
    });
  },

  roomSlider: function(){
    $('.accordionRooms__room__slider').slick({
      dots: false,
      infinite: true,
      speed: 500,
      cssEase: 'linear',
      pauseOnHover: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    });
  }

};






/**
 * INIZIALIZZAZIONE SITO
 */
(function () {

  siteGlobal.initReady();

  $( window ).on("load",function() {
      siteGlobal.initLoad();
      siteGlobal.initFirstLoad();
  });

  $(window).resize(function(){
    siteGlobal.initResize();
  });
  

  if(typeof $(window).resizeend === 'function'){
    $(window).resizeend(function () {
      siteGlobal.initResizeEnd();
    });
  } else {
    $(window).resize(function(){
      siteGlobal.initResizeEnd();
    });
  }

  $(window).scroll(function(){
    siteGlobal.initScroll();
  });

}());